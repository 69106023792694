<template>
  <base-modal
    v-bind="attrs"
    disable-click-on-overlay-close
    :overlay-class="isModalMaximized ? '' : ''"
    :popup-class="isModalMaximized ? 'modal-full mb-64' : 'modal-xl mb-64 modal-min-width'"
    @visible="initModalContent()"
    @hidden="reset()"
  >
    <template #modalHeader>
      <header class="px-2.5 py-2 bg-custom-gray-10 flex justify-between items-center border-b border-custom-gray-8 sticky top-0 z-50">
        <span class="text-black text-sm font-semibold">
          {{ selectedSubmissionTabId ? `${selectedSubmissionTabId} - ` : '' }}
          {{ submissionFormMode === 'EDIT' ? submissionForm.licenseeName : '' }}
          {{ submissionFormMode === 'DRAFT' ? 'Draft' : '' }}
        </span>
        <div class="flex items-center">
          <base-svg
            class="h-4 w-4 text-custom-gray-7 inline-block hover:text-gray-900 mr-4"
            :class="[isSubmissionLoading ? 'cursor-wait' : 'cursor-pointer']"
            src="icons/minus.svg"
            :svg-attributes="{ class: 'h-full w-full' }"
            tag="span"
            @click="handleMinimize()"
          />
          <base-svg
            class="h-4 w-4 text-custom-gray-7 inline-block hover:text-gray-900 cursor-pointer mr-4"
            :class="isModalMaximized ? 'h-4 w-4' : 'h-3.5 w-3.5'"
            :src="isModalMaximized ? 'icons/windowRestoreDown.svg' : 'icons/windowMaximize.svg'"
            :svg-attributes="{
              class: 'h-full w-full'
            }"
            tag="span"
            @click="handleMaximize()"
          />
          <base-svg
            class="h-5 w-5 text-custom-gray-7 inline-block hover:text-gray-900"
            :class="[isSubmissionLoading ? 'cursor-wait' : 'cursor-pointer']"
            src="icons/cross.svg"
            :svg-attributes="{ class: 'h-full w-full' }"
            tag="span"
            @click="handleClosingSubmission({
              submissionId: submissionForm.submissionId,
              licenseeName: submissionForm.licenseeName
            })"
          />
        </div>
      </header>
    </template>
    <template #modalBody>
      <div
        v-show="isSubmissionLoading"
        class="flex items-center justify-center w-full py-24 h-screen sm:py-28 md:py-36 lg:py-52"
      >
        <base-svg
          class="h-4 w-4 mr-1 text-primary-red inline-block"
          src="icons/circleSpinner.svg"
          tag="span"
        />
        Loading ...
      </div>

      <div
        v-show="!isSubmissionLoading && (!submissionForm || !submissionForm.submissionId)"
        class="flex items-center justify-center w-full py-24 h-screen sm:py-28 md:py-36 lg:py-52"
      >
        Submission Details are not available. Please close the form and try again.
      </div>

      <brand-assurance-submission-form
        :class="[ (isSubmissionLoading || !submissionForm || !submissionForm.submissionId) ? 'opacity-0 h-8 overflow-hidden': '']"
        @closeSubmission="handleClosingSubmission({
          submissionId: submissionForm.submissionId,
          licenseeName: submissionForm.licenseeName
        })"
        @submissionCancelled="removeTab($event)"
        @submitAndNext="removeTab($event, true)"
      />
    </template>
  </base-modal>
  <!-- submission tabs -->
  <teleport
    v-if="attrs.modelValue || isModalMinimized"
    to="body"
  >
    <div class="w-full border-t fixed bottom-0 left-0 bg-gray-100 z-30 px-2 flex items-center flex-wrap shadow-sm justify-start">
      <div class="hide-scroll hide-scroll-width flex flex-nowrap overflow-x-auto">
        <div
          v-for="(submissionTab, sIndex) in submissionsTabList"
          :key="sIndex"
          class="py-1 px-1.5 m-1 flex items-center text-sm flex-shrink-0 hover:bg-gray-200"
          :class="[
            selectedSubmissionTabId === submissionTab.submissionId ? 'font-semibold bg-green-50 text-green-600 border border-green-200' : 'font-light bg-gray-300 text-gray-900',
            isSubmissionLoading ? 'cursor-wait' : 'cursor-pointer'
          ]"
          @click="switchSubmission(submissionTab.submissionId, true)"
        >
          <span class="mr-3">
            {{ submissionTab.submissionId }} - {{ submissionTab.licenseeName }}
          </span>
          <base-svg
            class="h-4 w-4 text-primary-gray inline-block hover:text-primary-red"
            :class="[isSubmissionLoading ? 'cursor-wait' : 'cursor-pointer']"
            src="icons/cross.svg"
            :svg-attributes="{ class: 'h-full w-full' }"
            tag="span"
            @click.stop="handleClosingSubmission({
              submissionId: submissionTab.submissionId,
              licenseeName: submissionTab.licenseeName
            })"
          />
        </div>
      </div>
      <!-- <base-svg
        class="h-3.5 w-3.5 text-black inline-block align-middle absolute top-1.5 right-0 cursor-pointer p-1 opacity-60 hover:opacity-100 hover:text-red-600"
        src="icons/arrowRight.svg"
        :svg-attributes="{
          class: 'h-full w-full'
        }"
        tag="span"
      /> -->

      <!-- on hover add alt text -->
      <base-svg
        class="h-8 w-8 text-black inline-block align-middle absolute top-1 right-0 cursor-pointer p-1 opacity-60 hover:opacity-100 hover:text-red-600"
        src="icons/cross.svg"
        :svg-attributes="{
          class: 'h-full w-full h'
        }"
        tag="span"
        @click.stop="handleClosingAllSubmission()"
      />
    </div>
  </teleport>
  <!-- close all submissions warning modal -->
  <base-modal
    v-model="isCloseAllSubmissionModalVisible"
    container-class="fixed z-40 inset-0 overflow-y-auto"
    overlay-class="bg-gray-900 opacity-60"
    popup-class="bg-white text-left overflow-hidden shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
  >
    <template #modalHeader>
      <header class="px-2.5 py-2 bg-custom-gray-10 flex justify-between items-center">
        <span class="text-black text-sm font-semibold">
          Confirm Close All Submissions
        </span>
        <base-svg
          class="h-5 w-5 inline-block text-custom-gray-7 hover:text-gray-900 cursor-pointer"
          src="icons/cross.svg"
          :svg-attributes="{
            class: 'h-full w-full'
          }"
          tag="span"
          @click="setCloseAllSubmissionVisibility(false)"
        />
      </header>
    </template>
    <template #modalBody>
      <div class="px-4 py-3">
        <div class="font-sm text-center mb-2">
          Are you sure to close all submissions?
        </div>
        <div class="text-center mt-2">
          <base-button
            class="mr-3"
            variant="btn-primary"
            text="Yes"
            @click="closingAllSubmission()"
          />
          <base-button
            variant="btn-link"
            text="No"
            @click="setCloseAllSubmissionVisibility(false)"
          />
        </div>
      </div>
    </template>
  </base-modal>
  <!-- close submission warning modal -->
  <base-modal
    v-model="isCloseSubmissionModalVisible"
    container-class="z-40"
    modal-title="Confirm Close Submission"
    @hidden="setCloseSubmissionVisibility(false)"
  >
    <template #modalBody>
      <div class="px-4 py-3">
        <div
          v-if="closingSubmission"
          class="font-sm text-center mb-2"
        >
          Are you sure to close submission <strong> {{ closingSubmission.submissionId || persistedSubmissionId }} <span v-if="closingSubmission.submissionId"> - {{ closingSubmission.licenseeName }} </span></strong> ?
        </div>
        <div class="text-center mt-2">
          <base-button
            class="mr-3"
            variant="btn-primary"
            text="Yes"
            :is-loading="isRemovingSubmission"
            @click="removeTab()"
          />
          <base-button
            variant="btn-link"
            text="No"
            @click="setCloseSubmissionVisibility(false)"
          />
        </div>
      </div>
    </template>
  </base-modal>
</template>

<script>
import { defineAsyncComponent, ref, computed, watch } from 'vue';
import { useStore } from 'vuex';
import useSubmissionForm from './submissionForm.js';
import useSubmissionLoadTracker from './submissionLoadTracker.js';
import useSubmissionFormModal from './submissionFormModal.js';

export default {
    name: 'BrandAssuranceSubmissionFormModal',

    components: {
        BrandAssuranceSubmissionForm: defineAsyncComponent(() => import('./BrandAssuranceSubmissionForm.vue')),
        BaseModal: defineAsyncComponent(() => import('@/components/generic-components/BaseModal.vue')),
        BaseSvg: defineAsyncComponent(() => import('@/components/generic-components/BaseSvg.vue')),
        BaseButton: defineAsyncComponent(() => import('@/components/generic-components/BaseButton.vue'))
    },

    inheritAttrs: false,

    props: {
        submissionFormMode: {
            type: String,
            default: 'EDIT',
            validator: (formMode) => ['EDIT', 'NEW', 'DRAFT'].includes(formMode),
            description: 'used for handling submission form in different scenarios'
        }
    },

    emits: [
        'hide',
        'show',
        'minimize'
    ],

    setup (props, { emit, attrs }) {
        const store = useStore();
        const { submissionForm, submissionFormMode, updateNotifierStatus, resetSubmissionForm, resetSubmissionFormValidations } = useSubmissionForm();
        const { submissionLoadingTracker } = useSubmissionLoadTracker();
        const { submissionsTabList, openSubmissionDetails, updateSubmissionsTabList, selectedSubmissionTabId, saveCurrentSubmission, persistedSubmissionId } = useSubmissionFormModal();

        const initModalContent = async () => {
            if (!submissionsTabList?.value.length) {
                console.log('No Submission is selected');
                return;
            }

            // Ignore the VISABLE event, this is a Switched submission
            if (__switchedSubmissionId) {
                __switchedSubmissionId = '';
                return;
            }

            const lastSubmissionInList = submissionsTabList.value[submissionsTabList.value.length - 1];

            // Disalbe the notifiers
            updateNotifierStatus(false);

            submissionFormMode.value = props.submissionFormMode;

            await openSubmissionDetails(lastSubmissionInList);

            // Enable the notifiers
            updateNotifierStatus(true);

            if (isModalMinimized.value) isModalMinimized.value = false;

        };

        // switch submission logic
        let __switchedSubmissionId = '';
        const switchSubmission = async (submissionId, shouldSaveCurrentSubmission, forceSwitch) => {
            if (!forceSwitch && isSubmissionLoading.value) return;

            // Disalbe the notifiers
            updateNotifierStatus(false);

            if (shouldSaveCurrentSubmission) await saveCurrentSubmission();
            else {
                // console.log('>>>> Resetting the Submission Form')
                resetSubmissionForm();
                resetSubmissionFormValidations();
            }

            const tmp = submissionsTabList?.value.find(s => s.submissionId === submissionId);
            await openSubmissionDetails({
                submissionId: tmp?.submissionId || submissionId,
                assignUser: tmp?.assignUser,
                submissionStatus: tmp?.submissionStatus,
                currentStepName: tmp?.currentStepName
            });

            __switchedSubmissionId = tmp?.submissionId || submissionId;
            if (isModalMinimized.value) emit('show');

            // Enable the notifiers
            updateNotifierStatus(true);
        };

        const switchSubmissionWhenSubmitNext = async (submissionId, shouldSaveCurrentSubmission, forceSwitch) => {
            if (!forceSwitch && isSubmissionLoading.value) return;

            // Disalbe the notifiers
            updateNotifierStatus(false);

            if (shouldSaveCurrentSubmission) await saveCurrentSubmission();
            else {
                resetSubmissionForm();
                resetSubmissionFormValidations();
            }

            const tmp = gridSubmissionsList?.value.data.find(s => s.submissionId === submissionId);
            __switchedSubmissionId = tmp?.submissionId || submissionId;

            await updateSubmissionsTabList([{
                submissionId: tmp?.submissionId || submissionId,
                assignUser: tmp?.assignUser,
                submissionStatus: tmp?.submissionStatus,
                currentStepName: tmp?.currentStep
            }], true);

            await openSubmissionDetails({
                submissionId: tmp?.submissionId || submissionId,
                assignUser: tmp?.assignUser,
                submissionStatus: tmp?.submissionStatus,
                currentStepName: tmp?.currentStep
            });

            if (isModalMinimized.value) emit('show');

            // Enable the notifiers
            updateNotifierStatus(true);
        };

        // closing submission logic
        const gridSubmissionsList = computed(() => store.getters['baSubmissions/getSubmissionsList']);
        const closingSubmission = ref(null);
        const isRemovingSubmission = ref(false);
        const removeTab = async (submissionId, submitAndNextBool = false) => {
            isRemovingSubmission.value = true;
            __switchedSubmissionId = '';
            const __subIdToBeRemoved = submissionId || closingSubmission.value?.submissionId || persistedSubmissionId.value;
            const __currentIdx = submissionsTabList.value.findIndex(e => e.submissionId === __subIdToBeRemoved);
            const filteredTabs = submissionsTabList.value.filter(tab => tab.submissionId !== __subIdToBeRemoved);
            let __gridCurrentIdx;
            let gridFilteredLists;
            let tmpGrid;
            if (submitAndNextBool) {
                __gridCurrentIdx = gridSubmissionsList?.value?.data.findIndex(d => d.submissionId === __subIdToBeRemoved);
                gridFilteredLists = gridSubmissionsList?.value?.data.filter(tab => tab.submissionId !== __subIdToBeRemoved);
                tmpGrid = __gridCurrentIdx >= 0 && __gridCurrentIdx <= (gridFilteredLists.length) ? __gridCurrentIdx : 0;
                filteredTabs.push(gridFilteredLists[tmpGrid]);
            }
            if (filteredTabs.length === 0) {
                // close modal if there are no tabs left
                emit('hide');
            } else {
                if (submitAndNextBool) {
                    submissionsTabList.value = filteredTabs;
                    await switchSubmissionWhenSubmitNext(gridFilteredLists[tmpGrid].submissionId, false, true);
                } else {
                    const tmp = __currentIdx >= 0 && __currentIdx <= (filteredTabs.length - 1) ? __currentIdx : 0;
                    await switchSubmission(filteredTabs[tmp].submissionId, false, true);
                    submissionsTabList.value = filteredTabs;
                }
            }

            closingSubmission.value = null;
            setCloseSubmissionVisibility(false);
            isRemovingSubmission.value = false;
        };

        // cancel logic
        const reset = () => {
            __switchedSubmissionId = '';
            if (!isModalMinimized.value) {
                store.dispatch('baSubmissions/closeAllOpenedSubmissions');
                submissionsTabList.value = [];
            }
        };

        const closingAllSubmission = () => {
            emit('hide');
            isCloseAllSubmissionModalVisible.value = false;
            store.dispatch('baSubmissions/closeAllOpenedSubmissions');
            submissionsTabList.value = [];
        };

        // minimize logic
        const isModalMinimized = ref(false);
        const handleMinimize = async () => {
            if (isSubmissionLoading.value) return;
            __switchedSubmissionId = '';

            await saveCurrentSubmission();
            emit('minimize');
            isModalMinimized.value = true;
        };

        // maximize logic
        const isModalMaximized = ref(false);
        const handleMaximize = () => {
            isModalMaximized.value = !isModalMaximized.value;
        };

        // close all submission logic
        const isCloseAllSubmissionModalVisible = ref(false);
        const setCloseAllSubmissionVisibility = (visibility) => {
            isCloseAllSubmissionModalVisible.value = visibility;
        };

        const handleClosingAllSubmission = () => {
            setCloseAllSubmissionVisibility(true);
        };

        // close submission modal logic
        const isCloseSubmissionModalVisible = ref(false);
        const setCloseSubmissionVisibility = (visibility) => {
            isCloseSubmissionModalVisible.value = visibility;
            if (!visibility) {
                closingSubmission.value = null;
            }
        };
        const handleClosingSubmission = (submission) => {
            if (isSubmissionLoading.value) return;

            setCloseSubmissionVisibility(true);
            closingSubmission.value = submission;
        };

        const isSubmissionLoading = ref(false);

        watch(
            submissionLoadingTracker,
            () => {
                if (
                    submissionLoadingTracker &&
                  (
                      submissionLoadingTracker.isOpeningSubmission ||
                    submissionLoadingTracker.isFormSubmitting ||
                    submissionLoadingTracker.loadingStyleGuides ||
                    submissionLoadingTracker.loadingSGCharacters ||
                    submissionLoadingTracker.collabDetailsLoading
                  )
                ) {
                    isSubmissionLoading.value = true;
                } else {
                    isSubmissionLoading.value = false;
                }
            }
        );

        return {
            attrs,
            initModalContent,
            // load submission
            isSubmissionLoading,
            submissionForm,
            // submission tabs
            submissionsTabList,
            selectedSubmissionTabId,
            switchSubmission,
            closingSubmission,
            removeTab,
            // cancel
            reset,
            // minimize
            isModalMinimized,
            handleMinimize,
            // maximize
            isModalMaximized,
            handleMaximize,
            // close submission modal
            isCloseSubmissionModalVisible,
            setCloseSubmissionVisibility,
            handleClosingSubmission,
            closingAllSubmission,
            handleClosingAllSubmission,
            isCloseAllSubmissionModalVisible,
            setCloseAllSubmissionVisibility,
            persistedSubmissionId,
            isRemovingSubmission
        };
    }
};
</script>

<style>
/* .test123 {
  display: flex;
  flex-wrap: nowrap;
  width: calc(100vw - 70px);
  overflow-x: auto;
} */

/* .xClass {
  position: absolute;
  top: 5px;
  right: 0;
  cursor: pointer
}

.arrowClass {
  position: absolute;
  top: 5px;
  right: 40px;
  cursor: pointer
} */

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scroll-width {
  width: calc(100vw - 70px);
}

/*
.hide-scroll::-webkit-scrollbar {
  display: none;
}

.hide-scroll {
  -ms-overflow-style: none;
  scrollbar-width: none;
} */
</style>
